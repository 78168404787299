import { createStore } from "redux"
import { OrganisationConfig } from "./model/organisation-config"
import { KeyValue } from "./utils/base"

export interface IApplicationState {
    sidebarShow: boolean
    organisationConfig?: OrganisationConfig
}

const initialState: IApplicationState = {
    sidebarShow: true,
    organisationConfig: undefined,
}

const changeState = (state = initialState, { type, ...rest }: KeyValue) => {
    switch (type) {
        case "set":
            return { ...state, ...rest }
        default:
            return state
    }
}

const store = createStore(changeState)
export default store
