import configData from "./config.json"

export interface AppConfig {
    apiUrl: string
    env: string
    version: string
}

const config: AppConfig = {
    apiUrl: "",
    env: "",
    version: "",
}

export { config }

function loadConfig() {
    config.apiUrl = configData.apiUrl
    config.version = configData.version
    config.env = configData.env
    return config
}
export { loadConfig }
